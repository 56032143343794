<template>
	<div class="article-admin">
		<el-table
		  :data="tableData"
		  style="width: 100%">
		  <el-table-column
		    label="ID"
		    width="50">
		    <template slot-scope="scope">
		      <span style="margin-left: 10px">{{ scope.row.aid }}</span>
		    </template>
		  </el-table-column>
		  <el-table-column
		    label="更新日期"
		    width="250">
		    <template slot-scope="scope">
		      <i class="el-icon-time"></i>
		      <span style="margin-left: 10px">{{ $moment(scope.row.updateTime).format('YYYY-MM-DD hh:mm:ss') }}</span>
		    </template>
		  </el-table-column>
		  <el-table-column
		    label="标题"
		    width="350">
		    <template slot-scope="scope">
		      <el-popover trigger="hover" placement="top">
		        <p>标题: {{ scope.row.title }}</p>
		        <p>分类: {{ scope.row.type }}</p>
				<p>tags: {{ scope.row.tags }}</p>
		        <div slot="reference" class="name-wrapper">
		          <el-tag size="medium">{{ scope.row.title }}</el-tag>
		        </div>
		      </el-popover>
		    </template>
		  </el-table-column>
		  <el-table-column
		    label="置顶"
		    width="50">
		    <template slot-scope="scope">
		      <span style="margin-left: 10px" v-text=" scope.row.pin?'是':'否' "></span>
		    </template>
		  </el-table-column>
		  <el-table-column
		    label="评论"
		    width="50">
		    <template slot-scope="scope">
		      <span style="margin-left: 10px" v-text=" scope.row.commentable?'是':'否' "></span>
		    </template>
		  </el-table-column>
		  <el-table-column label="操作">
		    <template slot-scope="scope">
		      <el-button
		        size="mini"
		        @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
		      <el-button
		        size="mini"
		        type="danger"
		        @click="handleDelete(scope.$index, scope.row)">删除</el-button>
		    </template>
		  </el-table-column>
		</el-table>
	</div>
</template>

<script>
  export default {
    data() {
      return {
        tableData: []
      }
    },
    methods: {
		articles(){
			let that = this
			this.$axios.post('articlesA').then(function(results){
				that.tableData = results.data
			},function(err){
			    console.log(err)
			})
		},
      handleEdit(index, row) {
		  this.$router.push({
			  name: 'adminArticle',
			  params: {
				  article: row
			  }
		  })
      },
      handleDelete(index, row) {
        console.log(index, row);
      }
    },
	created() {
		this.articles()
	}
  }
</script>

<style>
.article-admin{
	width: 60%;
	margin: 5em auto;
}
</style>

<template>
	<div class="admin">
		<Nav></Nav>
		<articleA></articleA>
		<UserA></UserA>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import articleA from '@/components/admin/ArticleA.vue'
import UserA from '@/components/admin/UserA.vue'
export default {
	components: {
		articleA,
		Nav,
		UserA
	},
	data() {
		return {
			articlesA: [],
		}
	},
	methods: {
	},
	created() {
		this.$store.commit('showLoading')
	}
}
</script>

<style>
</style>

<template>
	<div class="users-admin">
		<el-table
		  :data="tableData"
		  style="width: 100%"
		  max-height="700">
			<el-table-column
			  fixed
			  prop="uid"
			  label="UID"
			  width="50">
			</el-table-column>
			<el-table-column
			  prop="username"
			  label="用户名"
			  width="100">
			</el-table-column>
			<el-table-column
			  label="头像"
			  width="80">
			  <template slot-scope="scope">
				<el-avatar :size="50" :src="scope.row.avatar" @error="errorHandler">
				    <img :src="scope.row.avatar==null?'/img/kasuie/avatar_default.png':scope.row.avatar"/>
				</el-avatar>
			  </template>
			</el-table-column>
		  <el-table-column
		    label="注册日期"
		    width="160">
			 <template slot-scope="scope">
			   <span>{{ $moment(scope.row.createTime).format('YYYY-MM-DD hh:mm') }}</span>
			 </template>
		  </el-table-column>
		  <el-table-column
		    prop="lastLogin"
		    label="最后登录"
		    width="160">
			  <template slot-scope="scope">
			    <span>{{ $moment(scope.row.lastLogin).format('YYYY-MM-DD hh:mm') }}</span>
			  </template>
		  </el-table-column>
		  <el-table-column
		    prop="email"
		    label="邮箱"
		    width="170">
		  </el-table-column>
		  <el-table-column
		    prop="gender"
		    label="性别"
		    width="50">
		  </el-table-column>
		  <el-table-column
		    prop="countLogin"
		    label="次数"
		    width="50">
		  </el-table-column>
			<el-table-column
			  prop="level"
			  label="等级"
			  width="50">
			</el-table-column>
		  <el-table-column
		    fixed="right"
		    label="操作"
		    width="120">
		    <template slot-scope="scope">
		      <el-button
		        @click.native.prevent="deleteRow(scope.$index, tableData)"
		        type="text"
		        size="small">
		        移除
		      </el-button>
		    </template>
		  </el-table-column>
		</el-table>
	</div>
</template>

<script>
  export default {
    data() {
      return {
        tableData: []
      }
    },
    methods: {
		getUsers(){
			let that = this
			this.axios.post('getUsers').then(function(results){
				that.tableData = results.data
			},function(err){
				console.log(err);
			})
		},
        deleteRow(index, rows) {
			rows.splice(index, 1);
        },
		errorHandler() {
			return true
		}
    },
	created() {
		this.getUsers()
	}
  }
</script>

<style>
.users-admin{
	width: 60%;
	margin: 5em auto;
}
</style>
